import React from 'react';
import K from '~/k';
import * as Linking from 'expo-linking';
import queryString from 'query-string';
import Url from 'url-parse';
import UrlPattern from 'url-pattern';

import HomePage from '~/pages/home-page';
import OrderPage from '~/pages/order/order-page';
import OrdersPage from '~/pages/orders-page';
import InvoicePage from '~/pages/invoice-page';
import DrawingsPage from '~/pages/drawings/drawings-page';
import AdminProductsPage from '~/pages/admin/admin-products-page';
import AdminProductPage from '~/pages/admin/admin-product-page';
import AdminProductPropertiesPage from '~/pages/admin/admin-product-properties-page';
import AdminProductPropertyPage from '~/pages/admin/admin-product-property-page';
import AdminProductGraphicPage from '~/pages/admin/admin-product-graphic-page';
import AdminProductCategoriesPage from '~/pages/admin/admin-product-categories-page';
import AdminProductCategoryPage from '~/pages/admin/admin-product-category-page';
import AdminProductPropertyGroupPage from '~/pages/admin/admin-product-property-group-page';
import AdminProductPropertyGroupsPage from '~/pages/admin/admin-product-property-groups-page';
import AdminMediaIndexPage from '~/pages/admin/admin-media-index-page';
import AdminGuestsPage from '~/pages/admin/admin-guests-page';
import AdminUserGroupsPage from '~/pages/admin/admin-user-groups-page';
import AdminIntegrationsPage from '~/pages/admin/admin-integrations-page';

import HeaderContent from '~/components/header-content';

import { AppWrapper, Loading, Label, Link, copilot, startCopilot, getDevice } from '@symbolic/rn-lib';
import { connect, setAppData, updateOrgs, setActiveOrg, logIn, logOut } from '@symbolic/redux';
import { View, TouchableOpacity, Image, Modal } from 'react-native';
import { Notifications } from '~/components';
import { resourceActions, setActiveView } from '~/redux';
import { Switch, Route, Redirect } from 'react-router-native';
import { api } from '@symbolic/lib';
import { accentColorForOrg } from '~/helpers/org-helper';
import { getNameFor } from '~/helpers/product-order-helper';
import { getUserEmployeeOrGuest } from '~/helpers/get-user-employee-or-guest';

import xIcon from '~/assets/x-icon.png';
import _ from 'lodash';

class App extends React.PureComponent {
  state = {resourceStatus: 'unloaded'};
  originalUrlPathName = null;

  constructor(props) {
    super(props);

    if (K.isWeb) this.initialWebUrl = window.location.href;
  }

  componentDidMount() {
    this.originalUrlPathName = window.location.pathname;

    setTimeout(() => this.considerLoadingResources());

    this.props.copilotEvents.on('stepChange', ({order}) => {
      this.props.setActiveView({data: {copilotStep: order}});
    });

    this.props.copilotEvents.on('stop', () => {
      global.copilotRunning = false;
      global.isShowingHelpCopilot = false;
    });

    if (!K.isWeb) { //HINT this is only for when mobile app is open & running and someone visits a web link that sends them to the app
      Linking.addEventListener('url', this.handleUrlChange);
    }
  }

  componentWillUnmount() {
    this.props.copilotEvents.off('stepChange');
    this.props.copilotEvents.off('stop');

    if (!K.isWeb) Linking.removeEventListener('url', this.handleUrlChange);
  }

  componentDidUpdate() {
    this.considerLoadingResources();
  }

  handleUrlChange = async ({url}) => {
    if (url && !K.isMobileWeb) {

      var {goto: fullPath} = queryString.parse(`?${url.split('?')[1]}`); //HINT handle mobile browser sending user to app

      if (!K.isWeb && fullPath) {
        var [pathname, query] = _.split(fullPath, '?'); //HINT full path from mobile browser
      }
      else {
        var {pathname, query} = new Url(url); //HINT handle desktop/dynamic link

        fullPath = pathname + query;
      }

      var {userToken, shareToken, orgId, oneTimeCode} = queryString.parse(query);
      var exisitingUserToken = await sessionStore.getToken();

      if (shareToken && !exisitingUserToken) {
        shareToken = _.trim(shareToken);

        var {data: {orgRole}} = await api.request({uri: '/configurator/get-org-role-for-token', body: {shareToken}});

        if (orgRole === 'guest') {
          //HINT if guest uses shareable link -> update product order sharees
          await api.request({uri: '/configurator/product-order/handle-guest-share-link', body: {shareToken}});
        }
        else if (!_.includes(['owner', 'member', 'guest'], orgRole)) {
          //HINT if user is not logged in -> permit access to the product order
          userToken = _.trim(shareToken);
        }
        //HINT if employee uses shareable link -> show product order and do nothing else
      }

      userToken = _.trim(userToken);

      if (userToken && !exisitingUserToken) {
        var device = await getDevice({appKey: 'designEngine'});

        setTimeout(async () => {
          await sessionStore.setToken(userToken);

          if (!this.props.session.isLoggedIn) {
            await this.props.logIn({token: userToken, device});
          }

          setTimeout(async () => {
            await this.considerLoadingResources({}, orgId);

            var interval = setInterval(() => {
              if (this.props.session.isLoggedIn) {
                clearInterval(interval);

                // if (!(this.props.session.user && !this.props.session.user.hasPassword && oneTimeCode)) {
                //   this.routerProps.history.push(this.originalUrlPathName); //HINT specifically don't want query string in this case
                // }
              }
            }, 50);
          });
        });
      }
      else if (!K.isWeb) {
        this.routerProps.history.push(fullPath);
      }
    }
  };

  getRouterProps = async (routerProps) => {
    var isFirstRouterLoad = !this.routerProps;

    this.routerProps = routerProps;

    if (isFirstRouterLoad) {
      this.handleUrlChange({url: K.isWeb ? this.initialWebUrl : await Linking.getInitialURL()});
    }
  };

  considerLoadingResources = async ({forceReload = false} = {}, orgId) => {
    var invalidBuildNumber = false;

    if (!invalidBuildNumber && this.props.session.isLoggedIn && (this.state.resourceStatus === 'unloaded' || forceReload)) {
      if (!forceReload) this.setState({resourceStatus: 'loading'});

      try {
        var activeProductOrderIdPattern = new UrlPattern('/orders/:productOrderOrgSpecificId(/*)');

        if (activeProductOrderIdPattern.match(this.originalUrlPathName)) {
          var {productOrderOrgSpecificId} = activeProductOrderIdPattern.match(this.originalUrlPathName);
        }

        var {shareableOrderId} = queryString.parse(window.location.search);

        var productsResponse = await api.request({uri: '/configurator/products/get', body: {
          orgId: await sessionStore.get('activeOrgId'),
          productOrderOrgSpecificId, shareableOrderId,
          includeAdvancedOrderData: this.props.detailLevelFilterValue === 'advanced'
        }});

        global.fontFamily = _.get(this.props.session.activeOrg, 'appData.designEngine.ui.fontFamily');
        global.fontSize = _.get(this.props.session.activeOrg, 'appData.designEngine.ui.fontSize');

        if (_.includes([1798, 1053], this.props.session.activeOrg.id)) {
          var fontFamily = {1798: 'Helvetica Neue', 1053: 'DIN'}[this.props.session.activeOrg.id];

          _.forEach(K.fonts, font => {
            font.font = '14px ' + fontFamily;
            font.fontWeight = 'normal';
            font.fontFamily = fontFamily;
          });
        }

        await this.props.trackMedia({media: _.get(productsResponse, 'data.media'), reset: true});
        await this.props.trackProductPricingRules({productPricingRules: _.get(productsResponse, 'data.productPricingRules'), reset: true});
        await this.props.trackProductCategories({productCategories: _.get(productsResponse, 'data.productCategories'), reset: true});
        await this.props.trackProductOptions({productOptions: _.get(productsResponse, 'data.productOptions'), reset: true});
        await this.props.trackProductOptionClassifiers({productOptionClassifiers: _.get(productsResponse, 'data.productOptionClassifiers'), reset: true});
        await this.props.trackProductOptionClassifierGroups({productOptionClassifierGroups: _.get(productsResponse, 'data.productOptionClassifierGroups'), reset: true});
        await this.props.trackProductOrders({productOrders: _.get(productsResponse, 'data.productOrders'), reset: true});
        await this.props.trackProductProperties({productProperties: _.get(productsResponse, 'data.productProperties'), reset: true});
        await this.props.trackProductPropertyGroups({productPropertyGroups: _.get(productsResponse, 'data.productPropertyGroups'), reset: true});
        await this.props.trackProductRules({productRules: _.get(productsResponse, 'data.productRules'), reset: true});
        await this.props.trackProductGraphics({productGraphics: _.get(productsResponse, 'data.productGraphics'), reset: true});
        await this.props.trackProducts({products: _.get(productsResponse, 'data.products'), reset: true});
        await this.props.trackGuests({guests: _.get(productsResponse, 'data.guests'), reset: true});
        await this.props.trackUserGroups({userGroups: _.get(productsResponse, 'data.userGroups'), reset: true});

        setTimeout(() => {
          // this.props.setActiveOrg({activeOrgId: activeOrg.id});
          this.setState({resourceStatus: 'loaded'});

          if (this.isSharee) {
            setTimeout(() => {
              this.props.startCopilot({autopilot: true, key: 'processInstanceShowView', startArgs: [false, {scrollTo: ()=> null}]});
            }, 1000);
          }
        });
      }
      catch (error) {
        console.error(error);
      }
    }
  };

  startCopilot = options => {
    global.copilotRunning = true;

    startCopilot({
      start: this.props.start,
      setSessionStore: ({key, value}) => this.props.setAppData({key, value, appKey: 'designEngine'}),
      getSessionStore: (key) => _.get(this.props, `session.user.appData.designEngine.${key}`),
      ...options
    });
  };

  help = () => {
    this.startCopilot();
  };

  handleNotificationPress = () => {
    //TODO
  };

  render() {
    var activeOrg = this.props.session.activeOrg;
    var accentColor = accentColorForOrg({org: this.props.session.activeOrg});
    var orgId = _.get(this.props, 'session.activeOrg.id');
    var {startCopilot} = this;
    var {copilotEvents} = this.props;
    var sharedProps = {startCopilot, copilotEvents};
    var {isEmployee, isGuest, isGuestMode} = getUserEmployeeOrGuest({activeOrg});

    return (
      <AppWrapper
        appName={'Configurator'}
        appTagline={' '}
        appKey={'designEngine'}
        appDescription=' '
        hideBranding
        hideHeader={this.props.isPrinting || this.state.resourceStatus !== 'loaded'}
        onNotificationPress={this.handleNotificationPress}
        Notifications={Notifications}
        headerContent={HeaderContent}
        headerContentProps={{..._.pick(this, ['startCopilot', 'getBackLink'])}}
        setSessionStore={({key, value}) => this.props.setAppData({key, value, appKey: 'designEngine'})}
        getSessionStore={(key) => _.get(this.props, `session.user.appData.designEngine.${key}`)}
        trackUsers={this.props.trackUsers}
        getRouterProps={this.getRouterProps}
        accentColor={accentColor}
        noButtonColor
      >
        {(this.state.resourceStatus !== 'loaded') ? (
          <Loading text={'Loading up\n\nThis can take a few seconds\n\nHold tight...'}/>
        ) : (<>
          <Switch>
            {(isEmployee || isGuest || isGuestMode) && (
              <Route exact path={['', '/']} component={HomePage}/>
            )}
            {(isEmployee || isGuest || isGuestMode) && (
              <Route exact path='/orders' component={OrdersPage}/>
            )}
            <Route exact
              path='/orders/:productOrderOrgSpecificId'
              render={props => <OrderPage {...props} {...sharedProps}/>}
            />
            <Route exact path='/orders/:productOrderOrgSpecificId/drawings' component={DrawingsPage} />
            <Route exact path='/orders/:productOrderOrgSpecificId/documents' component={InvoicePage}/>
            <Route exact path='/orders/:productOrderOrgSpecificId/quote'
              render={props => (
                <Redirect to={`/orders/${props.match.params.productOrderOrgSpecificId}/documents`} />
              )}
            />
            {(this.props.session.isLoggedIn && this.props.session.activeOrg.role === 'owner') && (
              <>
                <Route exact path='/admin/products' component={AdminProductsPage}/>
                <Route exact path='/admin/products/:productId' component={AdminProductPage}/>
                <Route exact path='/admin/product-properties' component={AdminProductPropertiesPage}/>
                <Route exact path='/admin/product-properties/:productPropertyId' component={AdminProductPropertyPage}/>
                <Route exact path='/admin/product-property-groups' component={AdminProductPropertyGroupsPage}/>
                <Route exact path='/admin/product-property-groups/:productPropertyGroupId' component={AdminProductPropertyGroupPage}/>
                <Route exact path='/admin/product-categories' component={AdminProductCategoriesPage}/>
                <Route exact path='/admin/product-categories/:productCategoryId' component={AdminProductCategoryPage}/>
                <Route exact path='/admin/product-graphics/:productGraphicId' component={AdminProductGraphicPage}/>
                <Route exact path='/admin/media' component={AdminMediaIndexPage} />
                <Route exact path='/admin/guests' component={AdminGuestsPage} />
                <Route exact path='/admin/user-groups' component={AdminUserGroupsPage} />
                <Route exact path='/admin/integrations' component={AdminIntegrationsPage} />
                <Route exact path='/admin'>
                  <Redirect to='/admin/products' />
                </Route>
              </>
            )}
            <Route path={['/404/:type', '']} render={({location, match}) => {
              var type = _.startCase(match.params.type || '');

              return (
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                  {type === 'order' && (
                    <Label style={{paddingHorizontal: K.spacing * 2, maxWidth: 300, marginBottom: K.spacing * 3, textAlign: 'center'}}>{getNameFor({orgId, textTransform: 'You might need to ask for an invite or the order you\'re looking for might have been deleted'})}</Label>
                  )}
                  {_.includes(location.pathname, 'admin') && (
                    <Label style={{paddingHorizontal: K.spacing * 2, maxWidth: 300, marginBottom: K.spacing * 3, textAlign: 'center'}}>{' You don\'t have proper permissions to view this page.'}</Label>
                  )}
                  <Link to='/' style={{...K.button, backgroundColor: K.colors.gray, paddingHorizontal: K.spacing, width: 'auto'}}><Label>Go Home</Label></Link>
                </View>
              );
            }}/>
          </Switch>
          {!!this.props.activeMediumId && (
            <Modal style={{height: '100%', width: '100%', position: 'fixed', top: 0, left: 0, borderWidth: 0, zIndex: 1001, transform: 'translateZ(0)'}}>
              <View style={{backgroundColor: K.colors.gray, flex: 1, position: 'relative'}}>
                <TouchableOpacity
                  style={{alignSelf: 'flex-end', position: 'absolute', top: K.spacing, right: K.spacing, height: 20, width: 20, borderRadius: 100, backgroundColor: K.colors.gray}}
                  onPress={() => this.props.setActiveView({data: {activeMediumId: undefined}})}
                >
                  <Image source={xIcon} style={{...K.defaultIconSize}} />
                </TouchableOpacity>
                <View style={{flex: 1, margin: K.spacing * 4}}>
                  <Image source={this.props.media[this.props.activeMediumId].presignedUrl} style={{width: '100%', height: '100%', resizeMode: 'contain'}}/>
                </View>
              </View>
            </Modal>
          )}
        </>)}
      </AppWrapper>
    );
  }
}

export default copilot()(connect({
  mapState: (state) => {
    var detailLevelFilterValue = _.get(state, 'session.user.appData.designEngine.ordersPageSettings.detailLevelFilterValue', 'basic');

    return {
      ..._.pick(state.activeView.data, ['isPrinting', 'activeMediumId']),
      media: state.resources.media.byId,
      detailLevelFilterValue
    };
  },
  mapDispatch: {
    setAppData, updateOrgs, setActiveOrg, setActiveView,
    ..._.pick(resourceActions.media, ['trackMedia']),
    ..._.pick(resourceActions.users, ['trackUsers']),
    ..._.pick(resourceActions.products, ['trackProducts']),
    ..._.pick(resourceActions.productGraphics, ['trackProductGraphics']),
    ..._.pick(resourceActions.productPricingRules, ['trackProductPricingRules']),
    ..._.pick(resourceActions.productOptions, ['trackProductOptions']),
    ..._.pick(resourceActions.productOptionClassifiers, ['trackProductOptionClassifiers']),
    ..._.pick(resourceActions.productOptionClassifierGroups, ['trackProductOptionClassifierGroups']),
    ..._.pick(resourceActions.productProperties, ['trackProductProperties']),
    ..._.pick(resourceActions.productPropertyGroups, ['trackProductPropertyGroups']),
    ..._.pick(resourceActions.productCategories, ['trackProductCategories']),
    ..._.pick(resourceActions.productOrders, ['trackProductOrders']),
    ..._.pick(resourceActions.productRules, ['trackProductRules']),
    ..._.pick(resourceActions.guests, ['trackGuests']),
    ..._.pick(resourceActions.userGroups, ['trackUserGroups']),
    logIn, logOut
  }
})(App));
